@import '_typography.css';

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'HelveticaNeue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 300;
  background-color: #f0f4f9;
}

.date-range__label {
  display: block;
  width: 80%;
  margin-bottom: 3px;
}

.date-range__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* width: 95%; */
}

.datepicker__divider {
  /* margin-left: 0.9em; */
}

.filter__panel_picker .ant-calendar-picker-icon {
  display: none;
}

/* .datepicker__divider::before {
  background-color: #9b9b9b;
  border-radius: 11.88px;
  width: 12px;
  height: 2px;
  display: block;
  content: '';
  position: absolute;
  right: 187px;
  top: 13px;
} */

.form-title {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 0px;
  margin-top: 4px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.form-title-desc {
  color: #829ab1;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 8px;
  font-weight: 400;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.form-title-date {
  color: #829ab1;
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 8px;
  font-weight: 300;
}

.page__title-text {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.page__h1 {
  color: #373a3c;
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
  text-align: left;
  margin-bottom: 4px;
}

.short__span {
  width: 36px;
  border-bottom: 2px solid #00bdf2;
  height: 2px;
  display: block;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('_assets/fonts/HelveticaNeueThin.eot');
  src: url('_assets/fonts/HelveticaNeueThin.eot?#iefix')
      format('embedded-opentype'),
    url('_assets/fonts/HelveticaNeueThin.woff2') format('woff2'),
    url('_assets/fonts/HelveticaNeueThin.woff') format('woff'),
    url('_assets/fonts/HelveticaNeueThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('_assets/fonts/HelveticaNeueLight.eot');
  src: url('_assets/fonts/HelveticaNeueLight.eot?#iefix')
      format('embedded-opentype'),
    url('_assets/fonts/HelveticaNeueLight.woff2') format('woff2'),
    url('_assets/fonts/HelveticaNeueLight.woff') format('woff'),
    url('_assets/fonts/HelveticaNeueLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('_assets/fonts/HelveticaNeueMedium.eot');
  src: url('_assets/fonts/HelveticaNeueMedium.eot?#iefix')
      format('embedded-opentype'),
    url('_assets/fonts/HelveticaNeueMedium.woff2') format('woff2'),
    url('_assets/fonts/HelveticaNeueMedium.woff') format('woff'),
    url('_assets/fonts/HelveticaNeueMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('_assets/fonts/HelveticaNeueBold.eot');
  src: url('_assets/fonts/HelveticaNeueBold.eot?#iefix')
      format('embedded-opentype'),
    url('_assets/fonts/HelveticaNeueBold.woff2') format('woff2'),
    url('_assets/fonts/HelveticaNeueBold.woff') format('woff'),
    url('_assets/fonts/HelveticaNeueBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* 
================ SIDE MENU ================
*/

.SideMenu {
  background: #18379a;
  /* width: 100%; */
}

.sidemenu-item {
  font-size: 1rem !important;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  /* background-color: #00274e; */
}

.sidemenu-item:hover {
  background: #2443a7 !important;
}
.sidemenu-item:focus {
  background: #2443a7 !important;
}
.sidemenu-item:active {
  background: #2443a7 !important;
}

.sidemenu-item a {
  border-radius: 5px;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.sidemenu-item i {
  font-size: 1rem !important;
}

.MenuWrapper {
  display: flex;
  flex-direction: column;
  background-color: #18379a;
  height: 100%;
  overflow-y: scroll;
  width: 250px;
  text-align: left;
  padding: 1rem;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 9999999;
}

.menu-open {
  transform: translateX(0);
}

.BurgerMenu {
  position: absolute;
  top: 1%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 99999999999;
}

.BurgerMenu:focus {
  outline: none;
}

.BurgerMenu div {
  width: 2rem;
  height: 0.25rem;
  background: #142850;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

/* when .BurgerMenu is closed */
.BurgerMenu div:first-child {
  transform: rotate(0);
}
.BurgerMenu div:nth-child(2) {
  opacity: 1;
  transform: translateX(0);
}
.BurgerMenu div:nth-child(3) {
  transform: rotate(0);
}

/* when .BurgerMenu is open */
.BurgerMenu-open div {
  background-color: #dae1e7;
}
.BurgerMenu-open div:first-child {
  transform: rotate(45deg);
}
.BurgerMenu-open div:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}
.BurgerMenu-open div:nth-child(3) {
  transform: rotate(-45deg);
}

/* 
================ SUB MENU INSIDE MENU ================
*/

.sidemenu-submenu-item {
  background: #18379a;
  margin-bottom: -4px;
  transition: transform 0.3s ease-in-out;
}

.sidemenu-submenu-item ul li {
  font-size: 0.9rem !important;
  margin-left: 0px !important;
  transition: transform 0.3s ease-in-out;
}

/* 
================ MEDIA QUERY ================

*/

@media screen and (max-width: '600px') {
  .MenuWrapper {
    width: 100%;
    padding: 0rem;
  }

  .BurgerMenu {
    top: 2%;
  }
}

.stats__row {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 1em;
  grid-gap: 1em 5em;
  margin-bottom: 1em;
}

.stats__card {
  background-color: #ffffff;
  border-radius: 3px;
  width: 100%;
  /* height: 115.2px; */
}

.stats__card .ant-card-body {
  padding: 13px;
}

.stats__icon {
  margin-right: 5px;
  margin-top: 3px;
}

.total-stats__tag {
  border-radius: 1.92px;
  width: 67.2px;
  height: 23.04px;
  border: none;
  padding-top: 2px;
}

.total-stats__tag.blue {
  background-color: rgb(1, 116, 216, 0.15);
  color: #0174d8;
}

.total-stats__tag.green {
  background-color: rgba(25, 193, 101, 0.14);
  color: #19c165;
}

.total-stats__tag.yellow {
  background-color: rgba(255, 193, 6, 0.097);
  color: #ffc106;
}

.total-stats__tag.red {
  background-color: rgba(255, 115, 96, 0.14);
  color: #ff7360;
}

.yellow-text {
  color: #ffc106 !important;
}

.blue-text {
  color: #0174d8 !important;
}

.green-text {
  color: #19c165 !important;
}

.red-text {
  color: #ff7360 !important;
}

.stats__card-top {
  display: flex;
  justify-content: space-between;
}

.stats__card .card__title {
  color: #373a3c;
  font-size: 13.44px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-transform: capitalize;
}

.stats__card-content {
  margin-top: 5px;
}

.stats__h2 {
  color: #373a3c;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.stats__h3 {
  color: #373a3c;
  font-size: 21.6px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
}

.stats__h4 {
  color: #cccccc;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}

/* Graph part of the dashboard */
.dashboard__graph-wrapper {
  margin-top: 2em;
  margin-bottom: 2em;
}

.dashboard__graph-card {
  border: 1.03px solid #f3f4f6;
  border-radius: 3.08px;
  /* box-shadow: 0 2px 4px 0 rgba(35, 36, 37, 0.2); */
}

.dashboard__graph-card .ant-card-head {
  border-bottom: none;
}

.charts__card {
  border-radius: 3.17px;
  width: 100%;
  height: 348.99px;
}

.charts__title-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.charts__card-h4 {
  color: #373a3c;
  font-size: 18px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  text-transform: capitalize;
}

.charts__card-h3 {
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.12px;
  line-height: 29px;
  text-align: left;
}

.charts-transaction-stats {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 2em;
  padding-top: 1em;
}

.charts-transaction-stats h3 {
  color: #0174d8;
  font-size: 33.93px;
  font-weight: 400;
  line-height: 41px;
  text-align: left;
}

.index-tag {
  width: 103px;
  height: 32px;
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  padding-top: 6px;
  border-radius: 16.72px;
  margin-top: 1.5em;
}

.last-month {
  color: #97a4ba;
  font-size: 15.51px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  margin-bottom: 1.8em;
}

.last-month__value {
  color: #67758d;
  font-size: 23.27px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.last-month__divider {
  border-left: 1px solid #dee5ef;
  display: block;
  background-color: #dee5ef;
  width: 1px;
  height: 75px;
}

.charts__row {
  display: flex;
  justify-content: space-evenly;
  margin-top: 4em;
  margin-bottom: 1em;
}

.inside-donut-chart-label {
  position: absolute;
  top: 39%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.donut-chart__number {
  color: #232425;
  font-size: 22px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
}

.donut-chart__detail {
  color: #232425;
  font-size: 7px;
  font-weight: 400;
  line-height: 11px;
  opacity: 0.5;
  text-align: center;
}

.trans-rate {
  color: #97a4ba;
  font-size: 16.55px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.trans-rate.failed::before,
.trans-rate.successful::before {
  width: 17.27px;
  height: 17.24px;
  border-radius: 100%;
  display: block;
  content: '';
  position: absolute;
  transform: translate(-150%, 0%);
}

.trans-rate.failed::before {
  background-color: #dce1e8;
}

.trans-rate.successful::before {
  background-color: #19c165;
}

.trans-em {
  color: #373a3c;
}
/*  EDIT ANTD RADIO BUTTON */

.line-chart__radio .ant-radio-button-wrapper {
  border-radius: 3.08px;
  margin-left: 10px;
  margin-right: 10px;
}

.line-chart__radio .ant-radio-button-wrapper:hover {
  border-radius: 3.08px;
  margin-left: 10px;
  margin-right: 10px;
  color: #19c165;
}

/* .line-chart__radio
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  opacity: 0.1;
} */

.line-chart__radio
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #ffffff;
  background: #19c165;
  border-color: #19c165;
  -webkit-box-shadow: -1px 0 0 0 #19c165;
  box-shadow: -1px 0 0 0 #19c165;
}

.line-chart__radio .ant-radio-button-wrapper:not(:first-child)::before {
  /* background-color: #ffffff; */
  /* border: 1px solid #d9d9d9 */
}

.agents__card-title {
  color: #02567b;
  font-size: 50.42px;
  font-weight: 700;
  line-height: 61px;
  text-align: center;
}

.agents__card-desc {
  color: #373a3c;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.pad-xy-105 {
  padding: 105px 105px;
}

.transaction-volume {
  color: #02567b;
  font-size: 18px;
  line-height: 22px;
}

.total-volume {
  color: #373a3c;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}
.total-volume-stats {
  color: #02567b;
  font-size: 25px;
  font-weight: 500;
  line-height: 57px;
  text-align: center;
}

.volume-cat-stats {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.volume-count {
  color: #02567b;
  font-size: 20.31px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}

.agents__volume-stats-card {
  background-color: #f5f5f5;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agents__trans-stats-card {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testx {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.one {
  grid-column: 1 / 2;
  grid-row: 1/3;
}

.two {
  grid-column: 2 / 3;
  grid-row: 1/2;
}
.three {
  grid-column: 2 / 3;
  grid-row: 2/3;
}

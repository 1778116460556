.markdown__row {
  color: #373a3c;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  padding: 19px 0px;
}

.details_divider {
  display: block;
  background-color: rgba(55, 58, 60, 0.13);
  width: 100px;
  height: 1.5px;
  margin-left: -25px;
}

.markdown table {
  /* margin: 2em 0; */
  font-size: 14px;
  line-height: 1.5;
  line-height: 17px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

.markdown table tr {
  border: 1px solid rgba(55, 58, 60, 0.13);
  border-right: none;
  border-left: none;
}

.markdown table tr:first-child {
  border-top: none;
}
.markdown table tr:last-child {
  border-bottom: none;
}

.markdown > table th,
.markdown > table td {
  padding: 24px 16px;
  text-align: left;
}

.markdown table td:nth-child(2) {
  color: #373a3c;
  font-weight: 500;
  text-align: right;
}

.detail__refund-btn {
  background-color: #e8e8e8;
  border: 1px solid transparent;
  border-radius: 2.88px;
  color: #ffffff;
}

.detail__refund-btn.successful {
  background-color: #19c165;
}
.detail__refund-btn.failed {
  background-color: #ff7360;
}
.detail__refund-btn.pending {
  background-color: #ebb104;
}

.detail__refund-btn:hover,
.detail__refund-btn:focus {
  color: #ffffff;
  border-color: transparent;
}

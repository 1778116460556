.welcome_title {
  font-weight: 300;
  font-size: 16px;
}

.welcome_subtitle {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 32px;
  margin-top: 12px;
  line-height: 1;
}

.welcome_card {
  border-radius: 4px;
  /* min-height: 7.14em; */
  /* height: 7.14em; */
}

.welcome_card:hover {
  transform: translateY(4px);
}

.welcome_card__text {
  width: 50%;
  font-size: 18px;
}

.welcome_card__icon_wrapper {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: #b6e0fe;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.welcome_card__icon {
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .welcome_subtitle {
    font-size: 24px;
    width: 75%;
    margin-bottom: 24px;
  }
}

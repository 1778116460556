@media (min-width: 1200px) {
  .stats__row {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
  .filter__panel_picker.ant-calendar-picker {
    min-width: 50px !important;
    margin: 0 auto;
  }

  .ant-btn.medium__btn {
    width: auto;
  }

  .form__content {
    min-width: 400px;
  }
}

@media (min-width: 1400px) {
  .stats__row {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .inside-donut-chart-label {
    top: 45%;
  }
  .donut-chart__number {
    font-size: 27px;
  }

  .donut-chart__detail {
    font-size: 9px;
  }
}

@media screen and (max-width: 600px) {
  .testx {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* grid-auto-rows: minmax(100px, auto); */
  }
  .one {
    grid-column: 1 / 2;
    grid-row: 1/3;
  }

  .two {
    grid-column: 2 / 3;
    grid-row: 1/2;
  }
  .three {
    grid-column: 2 / 3;
    grid-row: 2/3;
  }
}
@media screen and (min-width: 1900px) {
}

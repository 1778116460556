.pagelayout {
  min-height: 100vh;
  background-color: #f0f4f9;
}

.pagelayout footer {
  background-color: #eff2f7;
  text-align: center;
}

.pagelayout__aside {
  /* background-color: #0b0956; */
  background-color: #142850;
  padding-top: 3em;
}

.pagelayout__aside .ant-menu-dark {
  color: #ffffff;
  /* background-color: #0b0956; */
  background-color: #142850;
}

.pagelayout__main {
  background-color: #f0f4f9;
  border-radius: 3px;
  border: 0.95952px solid #dfdfdf;
  min-height: calc(100vh - 40px);
  overflow: hidden;
  width: 100%;
}

.pagelayout__content {
  background-color: transparent;
  min-height: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: 33px;
  margin-right: 33px;
}

.notify__card {
  width: 370px;
  background: #eff2f7;
  border-radius: 3px;
  padding: 13px;
  margin-top: 21px;
  margin-right: 21px;
}
.notify__card-head-extra {
  color: #373a3c;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: right;
}

.notify__card .ant-card-head {
  border: none;
  border-bottom: none;
  padding: 0;
}

.notify__card .ant-card-head-title {
  color: #373a3c;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
}

.notify__card .ant-card-body {
  padding: 0px;
  zoom: 1;
}
.notify__card .ant-card-body p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* SIDE MENU */
.sidemenu__image {
  margin-right: 12px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: rgba(255, 255, 255, 0.32);
  border-left: 4px solid white;
}

.page-container {
  display: flex;
  margin: 24px 64px;
  flex-direction: column;
}

.overview-card {
  border-radius: 4px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overview-card .ant-card-body {
  height: 100%;
}

.overview-card-upperpart {
  margin-bottom: 24px;
}

.overview-card-lowerpart {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 32px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.submit-btn {
  font-weight: 300;
}

.service-card {
  height: 280px;
  border-radius: 4px;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.service-card-title {
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 0px;
  margin-top: 4px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.title-card-desc {
  color: #829ab1;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 8px;
  font-weight: 300;
}

.service-header-divider {
  font-size: 0.9em;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: #636e72;
  text-transform: uppercase;
  font-weight: bold;
}

.mt-extra-4 {
  margin-top: 5rem !important;
}

.center-div {
  width: 100%;
  padding: 30px 50px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-wrapper {
  margin-top: 32px;
  margin-bottom: 16px;
}

.overview-card__icon_wrapper {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: #3ae7e1;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.overview-card__icon {
  font-size: 24px;
  color: #002159;
}

@media screen and (max-width: 600px) {
  .page-container {
    display: flex;
    margin: 12px 24px;
    flex-direction: column;
  }
}

.filter__panel {
  background: transparent;
  /* box-shadow: 0px 1px 4px rgba(173, 173, 173, 0.5); */
  margin: 0px -12px;
  padding-top: 12px;
  border: none;
}

.agent__card {
  border-radius: 4px;
}

.filter__panel_btn_wrapper {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

.flex__row {
  display: flex;
  /* justify-content: space-between; */
}

label,
.ant-form-item-children label {
  color: #373a3c;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
}

.ant-form label {
  font-size: 12px;
  font-weight: 400;
}

.ant-card-head-title {
  font-weight: 400;
}

.result__panel {
  margin: 14px -12px;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
}

.result-title {
  color: #373a3c;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  text-transform: uppercase;
}

.download__btn {
  background-color: #ffffff;
  color: #2d6da9;
  border: 0.96px solid #2d6da9;
  border-radius: 2.88px;
  /* max-width: 174.63px; */
  height: 30.7px;
}

.upload-status__btn {
  background-color: #ffffff;
  color: #2d6da9;
  border: 0.96px solid #2d6da9;
  border-radius: 2.88px;
  height: 30.7px;
}

.btn-h-100 {
  height: 50px;
}

.table-scroll {
  overflow-y: scroll;
}

.text-center {
  text-align: center !important;
}

.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mb-4 {
  margin-bottom: 4em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.ml-3 {
  margin-left: 3em;
}
.ml-4 {
  margin-left: 4em;
}

.mr-1 {
  margin-right: 1em;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}
.px-3 {
  padding-right: 1rem !important;
}

.px-2 {
  padding-right: 2rem;
  padding-left: 2rem;
}
.bg-white {
  background-color: #fff !important;
}

.secondary-btn {
  background-color: #19c165;
  border-color: #19c165;
  border-radius: 2.88px;
  color: #ffffff;
}
.secondary-btn:hover {
  background-color: #1cd971;
  border-color: #1cd971;
  border-radius: 2.88px;
  color: #ffffff;
}

/* 
==========
OVERIDE ANTD STYLES 
==========
*/
/* Overiding Inputs - inputs, number input, select */
input[class*='ant-input'],
input[class*='ant-input-number-input'],
div[class*='ant-input-number'],
select[class*='ant-input'] {
  height: 50px !important;
}

div[class*='ant-select-selection'] {
  min-height: 50px !important;
}

textarea[class*='ant-input'] {
  min-height: 100px !important;
}

/* Antd pagination button */
.ant-pagination-item-active {
  font-weight: 500;
  border-color: transparent;
  background: #0174d8 !important;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
}

.ant-pagination-item a {
  color: #666666;
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  color: #666666;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: transparent;
  border: 1px solid transparent;
}

.agent__card .ant-card-body {
  padding: 0 12px 12px 12px;
}

.ant-form-item-control,
.ant-form-item-label {
  font-weight: 300 !important;
  line-height: 1;
  margin-bottom: 5px;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.ant-btn-primary {
  background-color: #0174d8;
  border-color: #0174d8;
  border-radius: 2.88px;
}

.data__table .ant-table-content {
  overflow-y: scroll;
  min-width: 100%;
}

.data__table .ant-table-thead > tr > th {
  color: #373a3c;
  line-height: 17px;
  font-size: 14px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 3em;
}

.ant-table-tbody {
  background-color: #ffffff;
}
.ant-time-picker-input {
  height: 100%;
}

.table-view__button {
  background-color: rgba(1, 116, 216, 0.1);
  border-radius: 3px;
  border: none;
  min-width: 4rem;
  height: 30px;
  color: #0174d8;
  font-size: 12px;
  font-weight: 400;
  /* line-height: 17px; */
}

/*{*/
/*  height: 45px !important;*/
/*  min-width: 200px;*/
/*}*/

.table__tag,
.evidence__tag {
  width: 100px;
  height: 30px;
  border-radius: 3px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.evidence__tag {
  display: inline;
}

.comment__lists .ant-list-item {
  display: inherit !important;
}

.not__found {
  max-width: 400px;
  z-index: 2;
  min-width: 300px;
}

.not__found h1 {
  font-size: 10rem;
  font-weight: 900;
  margin-bottom: 0;
}

.not__found .title {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  font-size: 1rem;
}

.text-warning {
  color: #ffc106 !important;
}

.text-error {
  color: #ff6051 !important;
}

.text-gray {
  color: #bdc3c7 !important;
  /* color: #bdc3c7; */
}

.text-deep-blue {
  color: #0b2e4f !important;
}

@media screen and (max-width: 600px) {
  .ant-form-item-control,
  .ant-form-item-label {
    margin-bottom: 8px;
  }

  .ant-form-item {
    margin-bottom: 12px !important;
  }
}

.profile-card {
  width: 100%;
}

.alert {
  width: 100%;
  margin-bottom: 2em;
}

.alert-link {
  font-weight: bold;
}
